import { Outlet } from 'react-router';

const MainLayotsTwo = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default MainLayotsTwo;
