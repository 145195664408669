import { useParams } from 'react-router-dom';
import { useState } from 'react';
import hooks from '../../data/hooks';
import NotFound from '../NotFound';
import '../../style/react.css';
import Boolen from '../../components/apicontext/Boolen';
import ChangeBoolen from '../../components/apicontext/ChangeBoolen';
import BoolenContext from '../../context/BoolenContext';

const SingleHooks = () => {
  const params = useParams();
  //console.log(params);

  const [boolen, setBoolen] = useState('false');
  const [like, setLike] = useState(0);
  // const location = useLocation();
  // console.log(location);

  const hook = hooks.find((hook) => hook.slug === params.hooksSlug);

  if (!hook) {
    return <NotFound />;
  }

  const pageUseContext = params.hooksSlug === 'usecontext';
  const pageUseState = params.hooksSlug === 'usestate';

  return (
    <>
      <h1>{hook?.title}</h1>
      <div className="hook-description">{hook?.description}</div>
      {hook.memorandum ? (
        <div className="hook-memorandum">{hook?.memorandum}</div>
      ) : null}
      <div className="hook-example">{hook?.example}</div>
      {/* <Link to=".." relative="path">
        Все хуки
      </Link> */}
      {pageUseState ? (
        <div>
          <button onClick={() => setLike(like + 1)}>like</button> {like}
        </div>
      ) : null}

      {pageUseContext ? (
        //передаем value={boolen} в провайдер, чтобы потом получить значение
        <BoolenContext.Provider
          value={{ boolen: boolen, setBoolen: setBoolen }}
        >
          <Boolen />
          <ChangeBoolen />
        </BoolenContext.Provider>
      ) : null}
    </>
  );
};

export default SingleHooks;
