import AllHooks from './AllHooks';

const ReactT = () => {
  return (
    <>
      <div>
        Пока что тут все хуки, в дальнейшем либо разнесу их по библиотекам, либо
        создам вкладку хуки с возможностью сортировки по библиотекам
      </div>
      <div>
        <AllHooks />
      </div>
      <div></div>
    </>
  );
};

export default ReactT;
