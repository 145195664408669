const Home = () => {
  return (
    <>
      <div>Home</div>
      <div>-useMemo</div>
      <div>портал</div>
      <div>
        реализуйте там интересные фичи: подписку/отписку на почтовую рассылку;
      </div>
      <div>фотогалерею;</div>
      <div>форму для регистрации и входа на сайт</div>
      <div>
        контактную форму с валидацией, сообщением о неверно введенной информации
        и подтверждением
      </div>

      <div>цитату дня – случайный вывод каких-то элементов из набора.</div>
      <div></div>
      <div></div>
      <div></div>
    </>
  );
};

export default Home;
