const hooks = [
  //Router:
  //React:
  //Redux:

  // {
  //   title: '',
  //   librarys: '',
  //   id: '',
  //   slug: '',
  //   description: '',
  //   memorandum: '',
  //   example: ``,
  // },
  {
    title: 'useHooks',
    librarys: '',
    id: '9',
    slug: '',
    description: '',
    memorandum: '',
    example: ``,
  },
  {
    title: 'useHooks',
    librarys: '',
    id: '8',
    slug: '',
    description: '',
    memorandum: '',
    example: ``,
  },
  {
    title: 'useHooks',
    librarys: '',
    id: '7',
    slug: '',
    description: '',
    memorandum: '',
    example: ``,
  },
  {
    title: 'useContext',
    librarys: 'React',
    id: '6',
    slug: 'usecontext',
    description:
      'Этот хук позволяет сохранять переменную или объект, и использовать ее между несколькими компонентами. Под самим же контекстом, понимают эту сохраненную величину.',
    memorandum: 'Позволяет передать данные минуя пропсы.',
    example: (
      <div>
        Файл context/BoolenContext.jsx
        <div>*****</div>
        <div>{'const BoolenContext = createContext({'}</div>
        <div>{`boolen: '',`}</div>
        <div>{'setBoolen: () => {}, // чтобы избежать ошибок'}</div>
        <div>{`});'}
       `}</div>
        <div>*****</div>
        <div>В папке apicontext изменяем контекст </div>
        <div>*****</div>
        <div>В файле SingleHooks.jsx</div>
        <div>*****</div>
        <div>{`<BoolenContext.Provider value={{ boolen: boolen, setBoolen: setBoolen }} >`}</div>
        <div>{`<Boolen />`}</div>
        <div>{`<ChangeBoolen />`}</div>
        <div>{`</BoolenContext.Provider>`}</div>
        <div>________________________________</div>
        <div>
          {`Provider созданет контейнер
        (обертку) вокруг компонентов <Boolen /> и <ChangeBoolen />, чтобы
        предоставить им общий контекст. Это позволяет компонентам внутри этого
        контейнера получать доступ к общим данным и функциям. В данном случае,
        через Provider передаются значения boolen и setBoolen, что позволяет
        всем компонентам, находящимся внутри Provider, использовать эти данные.`}
        </div>
      </div>
    ),
  },
  {
    title: 'useLocation',
    librarys: 'Router',
    id: '5',
    slug: 'uselocation',
    description:
      'Этот хук возвращает объект который содержит информацию полученную из адресной строки',
    memorandum: '',
    example: (
      <div>
        <div>{`const location = useLocation();`}</div>
        <div>{`console.log(location);`}</div>
      </div>
    ),
  },
  {
    title: 'useNavigate',
    librarys: 'Router',
    id: '4',
    slug: 'usenavigate',
    description:
      'Этот хук возвращает другую функцию, с помощью которой можно изменять локацию',
    memorandum: 'Позволяет перенаправлять (редирект)',
    example: (
      <div>
        <div>const navigate = useNavigate();</div>
        <div>{`navigate('..', {relative: 'path'})`}</div>
        <div>выход на один уровень выше от текущего пути</div>
        <div>*****</div>
        <div>где '..' это путь куда перенаправим</div>

        <div>{`{relative: 'path'}`} это опция, текущий путь</div>
      </div>
    ),
  },
  {
    title: 'useParams',
    librarys: 'Router',
    id: '3',
    slug: 'useparams',
    description:
      'Дает нам доступ к параметрам этого конкретного маршрута. params - это параметры, значения которых динамически устанавливаются в URL.',
    memorandum: 'Позволяет получить часть адрессной строки.',
    example: (
      <div>
        Файл App.js
        <div>*****</div>
        <div>
          {'<Route path="react/:hooksSlug" element={<SingleHooks />} />'}
        </div>
        <div>Где hooksSlug является параметром, который мы перехватим</div>
        <div>*****</div>
        <div>____________</div>
        Файл SingleHooks.jsx
        <div>*****</div>
        <div>{'const params = useParams();'}</div>
        <div>{'console.log(params)'}</div>
        <div>*****</div>
      </div>
    ),
  },
  {
    title: 'useEffect',
    librarys: 'React',
    id: 2,
    slug: 'useeffect',
    description: (
      <div>
        Предназначен для запуска побочных эффектов (например, выполнение
        сетевого запроса или добавление обработчика событий) после монтирования
        и отрисовки компонента. Данная функция принимает колбек и массив
        зависимостей. <br />
      </div>
    ),
    memorandum: (
      <div>
        Что касается массива зависимостей, то логика следующая:
        <li>массив не указан: эффект запускается при каждом рендеринге</li>
        <li>указан пустой массив: эффект запускается только один раз</li>
        <li>
          указан массив с элементами: эффект запускается при изменении любого
          элемента
        </li>
      </div>
    ),
    example: (
      <div>
        {'function ExampleComponent() {'}
        <div>
          {
            'const [count, setCount] = useState(0); // Создаем состояние счетчика'
          }
        </div>

        <div>{'useEffect(() => {'}</div>
        <div>{`console.log('Счетчик был изменен до', count);`}</div>

        <div>{'return () => {'}</div>
        <div>{`console.log('Компонент удален');`}</div>
        <div>{'};'}</div>
        <div>{'}, [count]); // Зависимость от состояния счетчика'}</div>

        <div>{' return ('}</div>
        <div>{'<>'}</div>
        <div>{'<h1>Счетчик: {count}</h1>'}</div>
        <div>{'<button onClick={() => setCount(count + 1)}>Like</button>'}</div>
        <div>{'</>'}</div>
        <div>{'   );'}</div>
        <div>{'}),'}</div>
        <div>{'}'}</div>
      </div>
    ),
  },
  {
    title: 'useState',
    librarys: 'React',
    id: 1,
    slug: 'usestate',
    description:
      'Предназначен для управления состоянием компонента. Принимает на вход начальное состояние и возвращает массив из двух значений: текущего значения состояния и функции, которая обновляет это состояние.',
    memorandum:
      'const[текщее значение, функция] = useState(начальное состояние)',
    example: (
      <div>
        <div>const [like, setLike] = useState(0);</div>
        <div>
          {`<button onClick={() => setLike(like + 1)}>like</button> {like} `}
        </div>
      </div>
    ),
  },
];

export default hooks;
