const RouterT = () => {
  return (
    <>
      <div>
        React Router внешняя библиотека которая организует навигацую между
        страницами
      </div>
      <div>
        работает по принципу Client-side routing - роутинг (маршрутизация) на
        стороне клиента
      </div>
      <div>React Router Dom - для веб приложений</div>
      <div>React Native - для мобильных приложений</div>
      <div></div>
      <div></div>
    </>
  );
};

export default RouterT;
